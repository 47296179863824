import { TextEditor } from "ims-ui-kit";
import * as fileHandlerService from "../../../services/fileHandlerService";
export default function RichText(props) {
  return (
    <TextEditor
      {...props}
      onEachFileSelection={async function (file) {
        console.log("filename",file)
        let storageInformation = null;
        try {
          let { data } = await fileHandlerService.uploadFileToS3(file, {});
          storageInformation = data.uploadInformation;
        } catch (err) {
          console.log(err);
        }
        return storageInformation;
      }}
      mediaLinkGeneratorFn={async function (storageInformation) {
        let generatedLinkToShowInUI = null;
        try {
          let { data } = await fileHandlerService.getSignedUrl(
            storageInformation
          );
          generatedLinkToShowInUI = data.url;
        } catch (err) {
          console.log(err);
        }
        return generatedLinkToShowInUI;
      }}
    />
  );
}
