import { Badge, Col, Row, Select } from "ims-ui-kit";
import React from "react";
import { useParameters } from "../../../store/parametersStore";
import ReportContents from "../shared/ReportContents";
import DataTable from "./DataTable";
import SYRActivityEmissionChart from "./SYRActivityEmissionChart";
import SYRCategoryEmissionChart from "./SYRCategoryEmissionChart";
import SYRScopeEmissionChart from "./SYRScopeEmissionChart";
import { useSingleYearReport } from "./store";
import Notes from "../shared/Notes";
import Loading from "../../../components/Loading";

function Report() {
  let { reportingPeriods } = useParameters();
  const {
    isReportLoading,
    report,
    selectedReportingYear,
    selectReportingYear,
  } = useSingleYearReport();

  if (isReportLoading) return <Loading />;
  if (!report) return "Report not loaded";
  return (
    <React.Fragment>
      <div className="border-bottom">
        <ReportContents className="d-flex justify-content-between">
          <div className="">
            <h4>Interface NRM</h4>
            <p>Greenhouse Gas (GHG) Results</p>
          </div>
          <div className="text-end">
            <h4>Reporting period</h4>
            <div className="unstyled-select ">
              <Select
                defaultValue={selectedReportingYear}
                options={reportingPeriods.map((rp) => ({
                  value: rp.year,
                  label: `${rp.startDate} - ${rp.endDate}`,
                }))}
                onChange={selectReportingYear}
              />
            </div>
          </div>
        </ReportContents>
      </div>
      <ReportContents>
        <DataTable />
      </ReportContents>
      <Notes />
      <ReportContents>
        <Row>
          <Col md={12}>
            <h5 className="text-center mb-5">{2023} Emission break down</h5>
          </Col>
          <Col md={4}>
            <p className="text-dark text-center mb-2">
              {report?.reportingYear} Emissions by scope
            </p>
            <SYRScopeEmissionChart />
          </Col>
          <Col md={8}>
            <p className="text-dark text-center  mb-2">
              {report?.reportingYear} Emissions by activities
            </p>
            <SYRActivityEmissionChart />
          </Col>
          <Col md={12}>
            <p className="text-dark text-center mt-5 mb-2">
              {report?.reportingYear} Emissions by Categories
            </p>
            <SYRCategoryEmissionChart />
          </Col>
        </Row>
      </ReportContents>
    </React.Fragment>
  );
}

export default Report;
