import { useCallback, useEffect, useState } from "react";
import * as ccReportService from "../../services/ccReportService";
import useAPIResponse from "../../hooks/apiResponse";
import { useParameters } from "../../store/parametersStore";

function getDefaultReportingYear(reportingPeriods) {
  let now = new Date();
  let rp = reportingPeriods.find((rp) => rp.year === now.getFullYear());
  if (!rp) return null;
  return {
    value: rp.year,
    label: `${rp.startDate} - ${rp.endDate}`,
  };
}

export default function useYearlyReport() {
  let { reportingPeriods } = useParameters();
  const [report, setReport] = useState(null);
  const [reportBaseYear, setReportBaseYear] = useState(null);
  const [isReportLoading, setIsReportLoading] = useState(true); // defaults to true
  const [selectedReportingYear, setSelectedReportingYear] = useState(
    getDefaultReportingYear(reportingPeriods)
  );
  const { handleError } = useAPIResponse();

  const loadReport = useCallback(async function (reportingYear) {
    if (!reportingYear) {
      let now = new Date();
      reportingYear = now.getFullYear();
    }
    try {
      setIsReportLoading(true);
      let response = await ccReportService.getSingleYearReport({
        reportingYear,
        baseYearCompare: true,
      });
      setReport(response?.data?.singleYearReport);
      setReportBaseYear(response?.data?.baseYearReport);
    } catch (err) {
      handleError(err);
    } finally {
      setIsReportLoading(false);
    }
  }, []);
  useEffect(() => {
    loadReport(selectedReportingYear?.value);
  }, [selectedReportingYear]);
  function selectReportingYear(data) {
    setSelectedReportingYear(data);
  }
  return {
    report,
    reportBaseYear,
    isReportLoading,
    selectedReportingYear,
    selectReportingYear,
    loadReport,
  };
}
