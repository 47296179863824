import React from "react";
import Title from "./Title";
import Subtitle from "./SubTitle";
import Article from "./Article";
import List from "./List";
import { EmissionsTable, DataQualityInformationTable } from "./Table";
import { useParameters } from "../../../store/parametersStore";
import { useOrganisation } from "../../../store/organisationStore";
import CC_CONSTANTS from "../../../constants";
import { PiCaretUpDownBold } from "react-icons/pi";
import DataQualityResultsTable from "./DataQualityResultsTable";
import GHGBreakDownTable from "./GHGBreakDownTable";
import {
  Col,
  Row,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "ims-ui-kit";
import SYRCategoryEmissionChart from "./SYRCategoryEmissionChart";
import { useISO14064FullReport } from "./store";
import CategoryEmissionCompareChart from "./CategoryEmissionCompareChart";
import SYRScopeEmissionChart from "./SYRScopeEmissionChart";
import ScopeEmissionChart from "./ScopeEmissionChart";
import EmissionsCategoriesTable from "./EmissionsCategoriesTable";
import ActivitySummaryTableByCategory from "../shared/ActivitySummaryTableByCategory";
import classNames from "classnames";
import Loading from "../../../components/Loading";

function PrimaryHighlightedText({ children }) {
  return <span className="text-primary">{children}</span>;
}
function WarningHighlightedText({ children }) {
  return <span className="text-danger">{children}</span>;
}

function FullReport() {
  const { parameter, reportingPeriods, isParameterLoading } = useParameters();
  const {
    isReportLoading,
    report,
    selectedReportingYear,
    selectReportingYear,
  } = useISO14064FullReport();
  const { organisation } = useOrganisation();
  if (isReportLoading) return <Loading />;

  const plannedActions = [
    "Improve data collection and monitoring systems to ensure accurate and consistent activity data.",
    "Conduct regular reviews of emission factors to identify updates or refinements that may improve accuracy.",
    "Invest in training and capacity-building for our environmental management team to enhance their understanding of GHG quantification methodologies and best practices.",
    "Engage with suppliers and seek primary emission factors which meet the quality standards described by the GHG Protocol.",
  ];

  // Extracting data for emissions tables
  const extractData = (scopeIndex) => {
    return CC_CONSTANTS.CC_CATEGTORIES_WITH_DETAILS[scopeIndex].categories.map(
      (category) => {
        let relevance =
          parameter?.reportingBoundaries.find(
            (boundary) => boundary.category === category.name
          )?.relevance || "";

        return {
          category: category.name,
          description: category.description,
          examples: category.example,
          explanation: category.explanation[relevance],
          relevance: relevance,
        };
      }
    );
  };
  const dataQualityInformationData = () => {
    return Object.keys(CC_CONSTANTS.CC_DATA_QUALITY_INFORMATION).map(
      (gradeKey) => {
        const grade = gradeKey;
        const data = CC_CONSTANTS.CC_DATA_QUALITY_INFORMATION[gradeKey];
        return {
          grade: grade,
          definition: data.DEFINITION,
          activityDataCriteria: data.ACTIVITY_DATA_CRITERIA,
          emissionFactorCriteria: data.EMISSION_FACTOR_CRITERIA,
        };
      }
    );
  };

  const extractedScope1Data = extractData(0);
  const extractedScope2Data = extractData(1);
  const extractedScope3Data = extractData(2);

  const dataQualityInformation = dataQualityInformationData();

  const getReportingPeriod = () => {
    return `${
      reportingPeriods.find((rp) => rp.year === selectedReportingYear?.value)
        ?.startDate
    }
          -
          ${
            reportingPeriods.find(
              (rp) => rp.year === selectedReportingYear?.value
            )?.endDate
          }`;
  };

  if (isParameterLoading || isReportLoading) return "Loading report...";
  if (!parameter || !report) return "Report not loaded";

  return (
    <div className="px-5">
      {/* header section */}
      <header className="py-3">
        <h3 className="mb-5 text-center">
          <u>Greenhouse Gas (GHG) Emissions Report</u>
        </h3>
        <div className="mb-2">
          <strong>Company Name:</strong>{" "}
          <PrimaryHighlightedText>{organisation?.name}</PrimaryHighlightedText>
        </div>
        <div className="mb-2">
          <strong>Address:</strong>{" "}
          <PrimaryHighlightedText>
            {organisation?.addressStreet}
          </PrimaryHighlightedText>
        </div>
        <div className="mb-2">
          <strong>Reporting Year:</strong>
          <UncontrolledDropdown className="d-inline mx-2">
            <DropdownToggle className="border-0 text-dark">
              {selectedReportingYear?.value} <PiCaretUpDownBold />
            </DropdownToggle>
            <DropdownMenu className={"mb-3"}>
              {reportingPeriods?.map((rp) => (
                <DropdownItem
                  className={classNames("", {
                    "text-primary": selectedReportingYear?.value === rp.year,
                  })}
                  key={rp.year}
                  onClick={() => {
                    selectReportingYear({
                      value: rp.year,
                      label: rp.year,
                    });
                  }}
                >
                  {rp.year} ({rp.startDate} - {rp.endDate})
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="mb-2">
          <strong>Reporting Period:</strong> {getReportingPeriod()}
        </div>
      </header>
      <div>
        {/* 1. company Goals  */}

        <div className="py-3">
          <Title>1. Organisation Goals and Inventory Objectives</Title>
          <Article>
            This Greenhouse Gas (GHG) Emissions Report has been prepared in
            accordance with the ISO 14064-1:2018 standard. It includes a
            comprehensive inventory of our direct (Scope 1) and indirect (Scopes
            2 and 3) GHG emissions. We have defined our organisational and
            operational boundaries, ensuring data accuracy and transparency.
            We're committed to managing and reducing our GHG emissions in line
            with these international guidelines.
          </Article>

          <Subtitle>1.1. Persons Responsible</Subtitle>
          <Article>
            The preparation of this GHG report has been overseen by our{" "}
            <PrimaryHighlightedText>
              {organisation.contactPosition}
            </PrimaryHighlightedText>{" "}
            <PrimaryHighlightedText>
              {organisation.contactName}
            </PrimaryHighlightedText>
            , and supported by{" "}
            <WarningHighlightedText>"Nero Carbon LTD"</WarningHighlightedText>,
            an independent carbon consultancy.
          </Article>

          <Subtitle>1.2. Purpose of the Report</Subtitle>
          <Article>
            The purpose of this GHG report is to transparently communicate{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's greenhouse gas emissions performance to our stakeholders, track
            our progress towards emissions reduction targets, and identify areas
            for further improvement.
          </Article>

          <Subtitle>1.3. Intended Users</Subtitle>
          <Article>
            This report is intended for a wide range of users, including
            investors, customers, employees, and the broader community, who have
            an interest in{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's environmental performance and commitment to reducing GHG
            emissions.
          </Article>

          <Subtitle>1.4. Dissemination Policy</Subtitle>
          <Article>
            The GHG report will be made publicly available on our company
            website, as well as distributed to relevant stakeholders through
            various communication channels, including email updates, press
            releases, and social media.
          </Article>

          <Subtitle>1.5. Reporting Period and Frequency</Subtitle>
          <Article>
            This report covers our{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>{" "}
            reporting year
            <PrimaryHighlightedText>
              {getReportingPeriod()}
            </PrimaryHighlightedText>
            and will be updated annually to ensure timely and accurate
            information is provided to our stakeholders.
          </Article>
        </div>

        {/* 2. organisational Boundaries  */}

        <div className="py-3">
          <Title>2. Organisational Boundaries</Title>

          <Subtitle>2.1. Organisational Boundaries</Subtitle>
          <Article>
            This GHG report covers all of{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's operations, including our facilities, vehicles, and other assets.
            We have established the organisational boundaries for our GHG
            inventory using the{" "}
            <PrimaryHighlightedText>
              {parameter?.organisationalBoundary}
            </PrimaryHighlightedText>{" "}
            approach, as defined by ISO 14064-1. This means that we are
            reporting emissions from all facilities and activities where the
            organisation has operational control, regardless of any financial
            stake in those assets.
          </Article>

          <Subtitle>2.2. Emission Scopes and Categories</Subtitle>
          <Article>
            We have quantified and reported our GHG emissions in accordance with
            the GHG Protocol and ISO 14064-1:
            <ul>
              {" "}
              Scope 1: Direct emissions from sources owned or controlled by{" "}
              <PrimaryHighlightedText>
                {organisation?.name}
              </PrimaryHighlightedText>
              , such as stationary combustion, mobile combustion, and fugitive
              emissions.
            </ul>
            <ul>
              {" "}
              Scope 2: Indirect emissions from the consumption of purchased
              electricity.
            </ul>
            <ul>
              Scope 3: Other indirect emissions occurring outside of our
              organisational boundaries but attributable to our activities,
              including emissions from our supply chain, transportation and
              distribution, and commuting. More details about the reporting
              boundaries can be found in chapter 3 of this report.
            </ul>
          </Article>

          <Subtitle>2.3. Consolidation Methodology</Subtitle>
          <Article>
            To ensure the consistency and comparability of our GHG emissions
            data, we have adopted a uniform consolidation methodology across all
            our operations. This involves:
            <ul>
              Identifying and categorising all relevant emission sources within
              our organisational boundaries.
            </ul>
            <ul>
              Selecting appropriate quantification methodologies for each
              emission source, in line with ISO 14064-1 and the GHG Protocol.
            </ul>
            <ul>
              Collecting activity data (e.g., fuel consumption, value of
              purchases) and applying relevant emission factors to calculate GHG
              emissions.
            </ul>
            <ul>
              Aggregating emissions data by scope and category to provide a
              comprehensive overview of our GHG performance.
            </ul>
            We have used the{" "}
            <WarningHighlightedText>
              "Nero GHG Calculator"
            </WarningHighlightedText>{" "}
            developed by{" "}
            <WarningHighlightedText>Nero Carbon LTD</WarningHighlightedText>
            to consolidate our emissions and ensure the criteria outlined above
            are met. More details about this model can be found in the appendix
            of this report.
          </Article>

          <Subtitle>2.4. Changes in Boundaries and Methodologies</Subtitle>
          <Article>
            {`We continuously review and update our boundaries and methodologies to ensure the accuracy and relevance of our GHG inventory.
Any significant changes in our organisational boundaries, such as acquisitions or divestments, will be clearly disclosed in our GHG report,
along with the associated impacts on our emissions data. Similarly, any changes to our quantification methodologies, such as the adoption
of new emission factors or improvements in data quality, will be documented and explained in future reports.`}
          </Article>
        </div>

        {/* 3. Emission Categories  */}

        <div className="py-3">
          <Title>3. Emissions Categories</Title>
          <Article>
            This chapter provides a detailed description and explanation of the
            relevant emissions categories considered in{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's GHG inventory."
          </Article>
          <Article>
            Justifications for any excluded categories have been based on the
            principles of relevance, completeness, consistency, accuracy and
            transparency described in ISO14064-1. Categories may be excluded if
            they were determined to not be relevant to the needs of the intended
            users of this report. Additionally, their inclusion may have
            significantly impacted the accuracy of the report due to the high
            level of uncertainty of the data. We have ensured that any
            exclusions do not impede our intended users from making decisions
            with reasonable confidence. We are committed to providing clear and
            transparent explanations for any exclusions in our GHG reporting.
          </Article>
          <Subtitle>3.1. Scope 1 (Direct Emissions)</Subtitle>

          <Article>
            Scope 1 emissions are direct emissions from sources owned or
            controlled by{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            . We have considered the following categories for Scope 1 emissions:
          </Article>

          <EmissionsTable data={extractedScope1Data} />
          <Article>{`Scope 2 emissions are indirect emissions associated with the consumption of purchased electricity, steam, heating, and cooling. We have considered the following categories for Scope 2 emissions:`}</Article>
          <EmissionsTable data={extractedScope2Data} />
          <Article>
            Scope 3 emissions are other indirect emissions that occur outside of
            our organisational boundaries but are attributable to our
            activities. We have considered the following categories for Scope 3
            emissions:
          </Article>
          <EmissionsTable data={extractedScope3Data} />
          <Article>
            For each emissions category, we have identified and quantified the
            relevant GHG sources, using appropriate quantification methodologies
            and emission factors, as described in Chapter 2."&" This ensures
            that our GHG inventory provides a comprehensive and transparent
            overview of{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's emissions performance across all our activities and operations.
          </Article>
        </div>

        {/* 4. Quantified GHG inventory of emissions */}

        <div className="py-3">
          <Title>4. Quantified GHG inventory of emissions</Title>
          <Article>
            This chapter presents the quantified data results of our GHG
            inventory by emission category, comparison to the base year, along
            with a description of the methodologies, activity data, emission
            factors, uncertainties, accuracy impacts, and planned actions for
            reducing uncertainty in future inventories.
          </Article>
          <Subtitle>4.1. Results Summary</Subtitle>
          <h6>
            4.1.1 Emissions in the{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>{" "}
            Reporting Year
          </h6>
          <Row>
            <Col md="3">
              <SYRScopeEmissionChart />
            </Col>
            <Col md="9">
              <SYRCategoryEmissionChart />
            </Col>
          </Row>
          <h6>4.1.2 Changes in Emissions Since the Base Year</h6>
          <Article>Emissions categories separated by Scopes.</Article>
          <Row>
            <Col md="3">
              <ScopeEmissionChart />
            </Col>
            <Col md="9">
              <CategoryEmissionCompareChart />
            </Col>
          </Row>

          <Subtitle>
            4.2. Quantified Data Results by Emissions Category
          </Subtitle>
          <Article>
            Below is a summary of{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            's GHG emissions data for the{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>{" "}
            reporting year. Emissions categories described in section 3 of this
            report have been further grouped in accordance with ISO14064-1.
          </Article>
          <EmissionsCategoriesTable />
          <Subtitle>4.3. Methodologies and Activity Data</Subtitle>
          <Article>
            For each emission category, we used methodologies consistent with
            ISO 14064-1 and the GHG Protocol. Activity data, such as fuel
            consumption, electricity usage, and production volumes, were
            collected from internal records and external suppliers. Emission
            factors were sourced from high-quality secondary databases,
            including factors published annually by the UK Government.
          </Article>

          <Subtitle>4.4. Emission Factors</Subtitle>
          <Article>
            Emission factors were selected based on the best available data,
            considering the region, fuel type, and technology. Any changes to
            emission factors or updates in data sources were documented and
            explained in the inventory.
          </Article>
          <Article>
            More details about methodologies and emission factors can be found
            in the appendix.
          </Article>

          <Subtitle>4.5. Uncertainties and Accuracy Impacts</Subtitle>
          <Article>
            It was not possible to scientifically quantify the uncertainty
            associated with the activity data and emission factors used in the
            GHG assessment. Therefore, a qualitative assessment was adopted.
          </Article>
          <Article>
            For each emission source, the quality of both the activity data and
            the corresponding emission factor were awarded one of eight grades,
            from A+ (Exceptional Quality) to D (Low Quality).
          </Article>
          <Article>
            By calculating the weighted average of the grades - by total CO2e
            impact - it was possible to calculate the overall quality of each
            emissions category in the{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>{" "}
            reporting year.
          </Article>
          <Article>
            The details of each category's data quality can be found in the
            appendix of this report.
          </Article>

          <Subtitle>4.6. Planned Actions for Reducing Uncertainty</Subtitle>
          <Article>
            To reduce uncertainty in our future GHG inventories, we plan to take
            the following actions:
          </Article>
          <WarningHighlightedText>
            <List items={plannedActions} />
          </WarningHighlightedText>
          <Article>
            By implementing these actions, we aim to improve the accuracy and
            reliability of our GHG inventory, enabling more informed
            decision-making on GHG reduction efforts and better management of
            climate-related risks. Continuous improvement in our data
            collection, analysis, and reporting will help us track our progress,
            identify areas for further action, and demonstrate our commitment to
            reducing our environmental impact.
          </Article>
        </div>
        {/* Appendix  */}

        <div className="py-5">
          <Title>5. Appendix</Title>
          <Subtitle>5.1. Base Year Policy</Subtitle>
          <Article>
            As part of our commitment to accurately tracking and reporting our
            greenhouse gas (GHG) emissions,{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            has established the following base year recalculation policy."&"
            This policy is designed to ensure consistency and comparability in
            our emissions data and to align with the principles of ISO 14064-1,
            such as transparency, accuracy, consistency, and completeness.
          </Article>
          <Subtitle>5.1.1 Base Year</Subtitle>
          <Article>
            The base year for tracking and reporting GHG emissions is{" "}
            <PrimaryHighlightedText>
              {parameter?.baseReportingYear}
            </PrimaryHighlightedText>
            . This year has been selected because it represents a stable and
            accurate reference point for our company's operations, including
            data availability and organisational structure.
          </Article>
          <Subtitle>5.1.2 Triggers for Base Year Recalculation</Subtitle>
          <Article>
            {" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>
            will recalculate the base year emissions under the following
            circumstances:
          </Article>
          <WarningHighlightedText>
            <List
              items={[
                "Structural changes: Significant changes in the company's organisational structure, such as mergers, acquisitions, divestitures, or the inclusion/exclusion of specific operations or facilities, which impact the comparability of the GHG emissions data.",
                "Methodological changes: Changes in GHG quantification methodologies, emission factors, or activity data that materially affect the accuracy and comparability of the base year emissions data.",
                "Discovery of errors: Identification of significant errors or omissions in the base year emissions data that materially impact the accuracy and comparability of the data.",
              ]}
            />
          </WarningHighlightedText>
          <Subtitle>5.1.3 Recalculation Procedure</Subtitle>
          <Article>
            When a recalculation is triggered,{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            will take the following steps:
          </Article>
          <WarningHighlightedText>
            <List
              items={[
                "Identify the specific changes or errors that require base year recalculation.",
                "Collect the necessary data and information to accurately recalculate the base year emissions, following the same principles and methodologies used for the current reporting year.",
                "Recalculate the base year emissions, accounting for the identified changes or errors, and ensure consistency with the current reporting year's data.",
                "Document the reasons for the recalculation, the steps taken, and the impact on the base year emissions data.",
                "Update the GHG emissions inventory and related reports to reflect the recalculated base year emissions data.",
              ]}
            />
          </WarningHighlightedText>
          <Subtitle>5.1.4 Review and Communication</Subtitle>
          <Article>
            {" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            will periodically review this base year recalculation policy to
            ensure that it remains relevant, effective, and aligned with the
            latest guidance and best practices."&" Any changes to the policy
            will be documented and communicated to relevant stakeholders,
            including employees, management, and external reporting entities.
          </Article>
          <Subtitle>5.2. Treatment of Biogenic Emissions</Subtitle>
          <Article>
            In accordance with Annex D of the ISO 14064-1 standard,{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            has, where possible, calculated and seperately reported biogenic GHG
            emissions resulting from its direct emissions activities (scope 1),
            indirect emissions from energy use (scope 2) and indirect
            transportation and travel (scope 3).
          </Article>
          <Article>
            Due to data availability, it was not possible to estimate biogenic
            emissions for other indirect categories, as well as some activities
            within scope 1 and 2. In the DEFRA/BEIS emission factors, only
            biogenic emission factors are available for certain activities and
            certain units of measurement. For instance, biogenic emission
            factors for Forecourt fuels containing biofuel are not available for
            activity based on distance.
          </Article>
          <Article>
            + Moving forward,{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            recognises the importance of addressing these gaps in its reporting
            and is actively exploring avenues to resolve these issues in line
            with the recommendations and guidelines set out by the ISO 14064-1
            standard."&" It is committed to continuously improving its GHG
            emissions reporting to ensure a more comprehensive and transparent
            disclosure of its environmental impact.
          </Article>
          <Subtitle>5.3. Treatment of Electricity</Subtitle>
          <Article>
            In accordance with Annex E of the ISO 14064-1 standard,{" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            has developed and implemented a robust strategy for the treatment of
            electricity within its Greenhouse Gas (GHG) emissions reporting.{" "}
          </Article>
          <Article>
            The GHG assessment both the location-based and market-based methods
            of reporting, each providing a unique perspective on its
            environmental footprint associated with electricity consumption.
          </Article>
          <Article>
            The location-based method assesses the average energy mix of the
            grid where the electricity is consumed, taking into account all the
            GHG emissions from all the generation sources within that
            geographical boundary. This approach enables the company to account
            for the emissions associated with its electricity consumption based
            on the emissions intensity of the local grid
          </Article>
          <Article>
            On the other hand, the market-based method reflects the choice the
            company makes in selecting its energy suppliers or energy contracts.
            In this method, the emissions associated with the company's
            electricity consumption are determined based on the specific
            attributes of the contracted electricity products that the company
            has chosen to purchase, providing these attributes comply with the
            quality criteria outlined in Annex E of the ISO 14064-1 standard.
          </Article>
          <Article>
            This dual-method approach provides a comprehensive understanding of
            the company's electricity-related GHG emissions. While the
            location-based method offers insight into the emissions based on the
            physical reality of the grid, the market-based method provides an
            account of the company's specific energy choices.
          </Article>
          <Subtitle>5.4 About the Quantification Model</Subtitle>
          <Article>
            {" "}
            <PrimaryHighlightedText>
              {organisation?.name}
            </PrimaryHighlightedText>{" "}
            has selected the{" "}
            <WarningHighlightedText>
              Nero Carbon Calculator
            </WarningHighlightedText>
            , a Web-based calculator developed and published by{" "}
            <WarningHighlightedText>Nero Carbon LTD</WarningHighlightedText>, as
            the model for estimating its GHG emissions.
          </Article>
          <Subtitle>5.4.1 Methodology</Subtitle>
          <Article>
            The GHG assessment was conducted using{" "}
            <WarningHighlightedText>Nero Carbon LTD</WarningHighlightedText>'s
            Greenhouse Gas (GHG) Calculator based on activity data submitted by
            the reporting company multiplied by high quality emission factors:
          </Article>
          <br />
          <center>
            <b>Activity Data x GHG Emission Factor = Total Emissions</b>
          </center>
          <Article>
            GHGs covered by the Kyoto Protocol - carbon dioxide (CO2), methane
            (CH4), nitrous oxide (N2O), hydrofluorocarbons (HFCs),
            perfluorocarbons (PFCs), sulphur hexafluoride (SF6) and nitrogen
            trifluoride (NF3) - have been expressed in tonnes of carbon dioxide
            equivalent (tCO2e). CO2e is the universal unit of measurement to
            indicate the global warming potential (GWP) of GHGs, expressed in
            terms of the GWP of one unit of carbon dioxide.
          </Article>
          <Article>
            <ul>
              <b>Carbon Dioxide (CO2):</b> GWP of 1 (by definition)
            </ul>
            <ul>
              <b>Methane (CH4):</b> [GWP of 25]
            </ul>
            <ul>
              <b>Nitrous Oxide (N2O):</b> [GWP of 298]
            </ul>
            <ul>
              <b>Hydrofluorocarbons (HFCs):</b> GWP values for HFCs vary
              depending on the specific gas.
            </ul>
            <ul>
              <b>Perfluorocarbons (PFCs):</b> PFCs have varying GWP values
              depending on the gas.
            </ul>
            <ul>
              <b>Sulphur Hexafluoride (SF6):</b> [GWP of 22800]
            </ul>
            <ul>
              <b>Nitrogen Trifluoride (NF3):</b> [GWP of 17200]
            </ul>
          </Article>
          <Article>
            The assessment and methodology conforms to The GHG Protocol
            Corporate Accounting and Reporting Standard, The GHG Protocol
            Corporate Value Chain (Scope 3) Standard, and ISO 14064-1:2018.
          </Article>
          <Article>
            Secondary emission factors for volume, mass and distances were
            sourced from the UK Government's Department for Environment, Food &
            Rural Affairs (DEFRA) and the Department for Energy Security and Net
            Zero Conversion Factors databases.
          </Article>
          <Article>
            £ Spent emission factors were sourced from DEFRA's UK and England's
            Carbon Footprint, conversion factors by SIC code.
          </Article>
          <Article>
            Any supply-specific custom emission factors employed should meet the
            scope and quality requirements described in The GHG Protocol
            Corporate Value Chain (Scope 3) Standard.
          </Article>
          <Subtitle>
            5.4.2 Characteristics and Justifications of the Chosen Model
          </Subtitle>
          <Article>
            The selected this model was chosen based on the following
            characteristics and justifications:
          </Article>
          <Article>
            <strong>
              a) Accurate representation of emissions and removals:
            </strong>{" "}
            The calculator is Greenhouse Gas (GHG) Protocol compliant and uses
            high-quality emission factors published by the UK Government's
            Department for Environment, Food & Rural Affairs (DEFRA) and the
            Department for Business, Energy and Industrial Strategy (BEIS),
            ensuring that the emissions are accurately represented according to
            widely recognised standards and methodologies.
          </Article>
          <Article>
            <strong>b) Limits of application:</strong> The{" "}
            <WarningHighlightedText>
              "Nero GHG Calculator"
            </WarningHighlightedText>
            includes a extensive library of secondary emission factors as well
            as the ability to add custom-emission factors. The tool's limits
            will depend heavily on the quality of the activity data submitted
            and the accuracy of supplier-specific emission factors. Seeking
            third-party verification of the GHG assessment can help to overcome
            this.{" "}
          </Article>
          <Article>
            {" "}
            <strong>c) Uncertainty and rigour:</strong> The calculator's
            emission factors, sourced from DEFRA/BEIS, are regularly updated and
            based on robust methodologies. Although some level of uncertainty is
            inherent in GHG estimation, the calculator ensures that the best
            available data is used, minimising uncertainty and maintaining
            rigour in the calculations.
          </Article>
          <Article>
            <strong>Reproducibility of results:</strong>d) The calculator uses a
            consistent set of formulae and emission factors that are within
            locked cells to avoid calculation errors. This ensures that the
            results can be reproduced when the same input data is provided,
            enhancing the reliability and credibility of the GHG inventory.
          </Article>
          <Article>
            <strong>e) Acceptability of the model:</strong> Given the
            calculator's compliance with the GHG Protocol and usage of UK
            Government emission factors, it is widely accepted as a suitable
            tool for estimating GHG emissions in the context of the
            organisation's operations.
          </Article>
          <Article>
            <strong>f) Origin and level of recognition of the model:</strong>{" "}
            The calculator is based on the GHG Protocol, a globally recognised
            standard for GHG accounting and reporting, and uses emission factors
            from DEFRA/BEIS, a reputable source of environmental data in the
            United Kingdom. This ensures that the model is well-grounded in
            established methodologies and recognised best practices.
          </Article>
          <Article>
            <strong>g)Consistency with the intended use:</strong> The calculator
            is designed to estimate GHG emissions in line with the
            organisation's reporting requirements and sustainability objectives.
            By using a GHG Protocol compliant calculator with high quality
            emission factors, the organisation can ensure that its GHG inventory
            is consistent with its intended use, providing accurate and reliable
            data for decision-making and progress tracking.
          </Article>
          <Article>
            In summary, the organisation has chosen the{" "}
            <WarningHighlightedText>
              Nero Carbon Calculator
            </WarningHighlightedText>
            as its GHG estimation model due to its accuracy, reliability,
            consistency with established standards, and suitability for the
            organisation's intended use. This choice ensures that the
            organisation's GHG inventory is robust, credible, and aligned with
            its sustainability goals.
          </Article>
          <Subtitle>5.5. Data Quality and Uncertainty Full Results</Subtitle>
          <Subtitle>5.5.1 Data Quality Methodology</Subtitle>
          <Article>
            Activity data were scored by the reporting company within the Nero
            GHG Calculator. Users were supplied with a qualification matrix to
            help decide the most appropriate grade. The same exercise was
            conducted by{" "}
            <WarningHighlightedText>Nero Carbon LTD</WarningHighlightedText> for
            the default emission factors and these scores were locked within the
            calculator.
          </Article>
          <div>
            <DataQualityInformationTable data={dataQualityInformation} />
          </div>
          <Subtitle>5.5.2 Data Quality Full Results</Subtitle>
          <DataQualityResultsTable />
          <Subtitle>
            5.6 GHG Breakdown of Direct Emissions for{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>
          </Subtitle>
          <Article>
            ISO14064-1 requires reporting organisations to report Direct
            emissions (Scope 1) separated by individual GHG gasses. These gasses
            are presented in tonnes of carbon dioxide equivalent (tCO2e).
          </Article>
          <GHGBreakDownTable />
          <Subtitle>
            5.7. Activity Breakdown for{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>
          </Subtitle>
          <Article>
            All activity and emissions data for the{" "}
            <PrimaryHighlightedText>
              {selectedReportingYear?.value}
            </PrimaryHighlightedText>{" "}
            reporting year.
          </Article>
          <ActivitySummaryTableByCategory
            reportingYear={selectedReportingYear?.value}
          />
        </div>
      </div>
    </div>
  );
}

export default FullReport;
