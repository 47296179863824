import Index from "./Index";

const routes = [
  {
    path: "reduction-plan",
    element: <Index />,
    // children: [
    //   {
    //     path: "",
    //     element: <Organisation />,
    //   },
    // ],
  },
];
export default routes;
