import { FormGroup, InputDate, Label } from "@ims-systems-00/ims-ui-kit";
import React from "react";
import InputError from "./InputError";
function DateInput({
  label = "",
  error = "",
  value = { date: undefined, label: undefined },
  dateFormat = "DD/MM/YYYY",
  onChange = function () {},
  ...props
}) {
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <InputDate
        variant="filled"
        inputProps={{
          placeholder: "Select a date",
          ...(value?.label && { value: value?.label }),
        }}
        {...(value?.date && {
          value: value?.date,
        })}
        closeOnSelect
        onChange={function (e) {
          let label = typeof e.format === "function" ? e.format(dateFormat) : e;
          onChange({ date: e, label });
        }}
        {...{ dateFormat }}
        {...props}
      />
      {error && <InputError>{error}</InputError>}
    </FormGroup>
  );
}

export default DateInput;
