import React from "react";
import { BsClipboardData } from "react-icons/bs";
import { PiChartLineUp } from "react-icons/pi";
import { Outlet } from "react-router-dom";
import Navigationbar from "../../components/Navigationbar";
import { CalculationContextProvider } from "./stores/calculationStore";

const navigations = [
  {
    path: "/categories",
    text: "Categories",
    icon: <BsClipboardData />,
  },
].map((item) => ({ ...item, path: "/calculations" + item.path }));

function CalculationsLayout() {
  return (
    <CalculationContextProvider>
      <Navigationbar navigaions={navigations} sticky={"top"} />
      <div className="content">
        <Outlet />
      </div>
    </CalculationContextProvider>
  );
}

export default CalculationsLayout;
