import { useNavigate } from "react-router-dom";
import CC_CONSTANTS from "../../constants";

export function useFindCategories() {
  const navigate = useNavigate();
  const vistingCategory =
    CC_CONSTANTS.CC_EMISSION_CATEGORY_NAMES.COMPANY_PREMISES;
  const course = {
    steps: [
      {
        target: `[data-tour-step="sidebar-menu-item-categories"]`,
        content: "Find your calculations here.",
      },
      {
        target: `[data-tour-step="${vistingCategory}"]`,
        content: "This is where you can find all the categories.",
      },
      {
        target: `[data-tour-step="tile-category-opener"`,
        content: "Open a category from here.",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: true,
        placement: "bottom",
        spotlightClicks: true,
      },
      {
        target: `[data-tour-step="add-calculation-button"`,
        content: "Add data from here.",
      },
      {
        target: `[data-tour-step="calculation-data-table"`,
        content: "All the emissions are listed here.",
      },
    ],
    callback: function (data) {
      let { status, index, setCurrentStep } = data;
      if (index === 0) {
        navigate("/calculations/categories");
      }
      if (index === 3) {
        navigate("/categories?category=" + vistingCategory);
        setTimeout(() => {
          setCurrentStep(4);
        }, 500);
        return;
      }
      setCurrentStep(index);
    },
  };
  return course;
}
