import { Badge } from "ims-ui-kit";
import React from "react";

function Layout() {
  return (
    <div className="content text-center">
      <Badge fade="success">
        Populate your data and your reduction plan will be available soon.
      </Badge>
    </div>
  );
}

export default Layout;
