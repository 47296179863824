import React from "react";
import {
  Document,
  Page,
  Text,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  View,
} from "@react-pdf/renderer";
import brandConfig from "config.js";

const styles = StyleSheet.create({
  pageStyles: {
    border: "1px solid red",
  },
  contents: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 70,
    paddingRight: 50,
  },
  reportTitle: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,

    textDecoration: "underline",
  },
  title: {
    fontSize: 18,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 16,
    marginBottom: 20,
  },
  article: {
    fontSize: 12,
    marginBottom: 10,
  },
  alert: {
    border: "1px solid #ffc107",
    backgroundColor: "#fff3cd",
    padding: 10,
    borderRadius: 4,
    color: "#988446",
    paddingHorizontal: 5,
    marginHorizontal: 2,
    marginBottom: 10,
  },
  alertText: {
    fontSize: 12,
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: 600,
  },

  table: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 10,
  },
  tableHeader: {
    borderTop: "none",
  },
});

const emissionCategoriesTableStyles = StyleSheet.create({
  colScope: {
    width: "20%",
  },
  colCategory: {
    width: "15%",
  },
  colNotes: {
    width: "15%",
  },
  coltCO2: {
    width: "20%",
  },
  colPercentageOfTotal: {
    width: "20%",
  },
  colChanges: {
    width: "14%",
  },
});

const Title = ({ style = {}, children }) => (
  <Text
    style={{
      ...styles.title,
      ...style,
    }}
  >
    {children}
  </Text>
);
const Subtitle = ({ style = {}, children }) => (
  <Text
    style={{
      ...styles.subTitle,
      ...style,
    }}
  >
    {children}
  </Text>
);
const Article = ({ style = {}, children }) => (
  <Text
    style={{
      ...styles.article,
      ...style,
    }}
  >
    {children}
  </Text>
);
const Contents = ({ style = {}, children }) => (
  <View
    style={{
      ...styles.contents,
      ...style,
    }}
  >
    {children}
  </View>
);
const Alert = ({ style = {}, children }) => (
  <View
    style={{
      ...styles.alert,
      ...style,
    }}
  >
    <Text style={styles.alertText}>{children}</Text>
  </View>
);
const Bold = ({ style = {}, children }) => (
  <Text
    style={{
      ...styles.bold,
      ...style,
    }}
  >
    {children}
  </Text>
);
const PDF = ({
  companyName = "(Name)",
  totalEmissions = 1111,
  address = "Street address",
  reportingYear = 2024,
  reportingStartDate = "dd/mm/yyyy",
  reportingEndDate = "dd/mm/yyyy",
  responsiblePerson = "Name of the Person",
  responsiblePersonPosition = "Position",
}) => {
  const report = (
    <Document>
      <Page size={"A4"}>
        <Contents>
          <Text style={styles.reportTitle}>Carbon Footprint Report</Text>
          <View>
            <Article>
              Company Name: <Bold> {companyName}</Bold>
            </Article>
            <Article>
              Address: <Bold> {address}</Bold>{" "}
            </Article>
            <Article>
              Reporting Period: <Bold>{reportingStartDate}</Bold> -{" "}
              <Bold>{reportingEndDate}</Bold>
            </Article>
            <Article>
              Responsibility for the report: <Bold>{responsiblePerson}</Bold>{" "}
              <Bold>{responsiblePersonPosition}</Bold>
            </Article>
            <Article>
              This carbon footprint report describes the inventory following ISO
              14064-1 requirements and the Greenhouse Gas (GHG) Protocol. The
              analysis shows that our total carbon emissions were{" "}
              <Bold>{totalEmissions}</Bold> tonnes of CO2 for the period.
            </Article>
            <Article>
              In addition to reviewing the absolute footprint, this report also
              benchmarks emissions <Bold>“per £ million turnover”</Bold> and{" "}
              <Bold>“per full-time employee (FTE)”</Bold>- shown in Table 1. As
              opposed to absolute metrics, these intensity metrics provide
              relative tCO2e figures for organisational carbon footprints.
            </Article>
            <Title>1. Goals and Objectives</Title>

            <Article>
              This Carbon Footprint Report provides a detailed examination of
              our carbon emissions (our carbon footprint) and details the
              methodology and approach taken. It has been prepared in accordance
              with ISO 14064-1:2018. It presents a detailed inventory of our
              direct (Scope 1) and indirect (Scopes 2 and 3) GHG emissions. We
              have established clear organisational and operational boundaries,
              identified data sources and included reliability metrics to ensure
              data accuracy and transparency.
            </Article>
            <Article>
              We use this report and the underlying data to review our carbon
              footprint and emission sources. Our ultimate goal is to manage and
              reduce our emissions more effectively to reach net zero. The
              report is intended for a broad audience, including investors,
              customers, employees, and the public.
            </Article>

            <Article>
              This report covers our reporting year{" "}
              <Bold>{reportingYear} </Bold> from{" "}
              <Bold>{reportingStartDate} </Bold> to
              <Bold>{reportingEndDate} </Bold> and will be updated annually to
              ensure our stakeholders receive timely and accurate information.
            </Article>

            <Article>
              The <Bold>{brandConfig.brandName}</Bold> generated the data for
              this report with the support of Bom Systems Ltd, a specialist
              technical carbon consultancy{" "}
              <a
                href="https://www.bom-systems.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                (www.bom-systems.co.uk)
              </a>
              .
            </Article>
            <Alert>
              A copy of this report is available upon request and circulated
              freely.
            </Alert>
          </View>

          <Title>2. Organisational Boundaries</Title>

          <Article>
            This GHG report covers all of our operations and facilities. We have
            established the organisational boundaries for our GHG inventory
            using the Operational Control approach, as defined in section 5.1 of
            ISO 14064-1:2018.
          </Article>
          <Article>
            Sources of GHG emissions have been identified and categorised
            according to the ISO 14064-1:2018 standard (Annex B) and the
            categories used in the GHG Protocol, which fall under scopes 1, 2,
            and 3.
          </Article>
        </Contents>
      </Page>
      <Page size={"A4"}>
        <Contents>
          <Article>
            <Bold>Scope 1:</Bold> Direct emissions from sources that we own or
            control, such as stationary combustion, mobile combustion,
            industrial processes, and fugitive emissions.
          </Article>
          <Article>
            <Bold>Scope 2:</Bold>Indirect emissions from imported (purchased)
            electricity consumption.
          </Article>
          <Article>
            <Bold>Scope 3:</Bold> Other indirect emissions in our value chain,
            including transportation, products purchased and used, indirect and
            distribution, and commuting. More details about the reporting
            boundaries appear later in this report.
          </Article>
          <Subtitle>Consolidation Methodology</Subtitle>

          <Article>
            Following the operational control approach, we have accounted for
            100 per cent of the GHG emissions over which we have operational
            control. This process involves identifying and categorising all
            relevant emission sources within our organisational boundaries. We
            select appropriate quantification methodologies for each emission
            source, aligning with ISO 14064-1 and the GHG Protocol.
          </Article>
          <Article>
            We collected activity data, such as energy consumption, travel and
            purchases, and applied relevant emission factors to calculate our
            GHG emissions, primarily the appropriate DEFRA/BEIS/DESNZ factors.
            Finally, we consolidated the emissions data to provide a clear and
            transparent GHG inventory.
          </Article>
          <Article>
            To consolidate our emissions and ensure we meet the outlined
            criteria, we have utilised the "{brandConfig.brandName}" developed
            by{" "}
            <a
              href="https://www.imssystems.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              iMS Technologies
            </a>
            . The appendix of this report provides more details about this
            model.
          </Article>

          <Title>3. Reporting boundaries</Title>

          <Article>
            The categories used for our report are those defined by the GHG
            protocol and Annex B of ISO 14064-1. As far as possible, we have
            included all our emissions from our activities under our operational
            control at a 5% materiality threshold. The tables and graphs below
            explain and justify the inclusion and exclusion of each category.
            Those emission categories we excluded appear as either NR (not
            relevant, i.e. an activity we do not conduct or below the total{" "}
            <Bold>5%</Bold> materiality threshold) or RNC (relevant, not
            calculated). 's GHG inventory."
          </Article>
          <Subtitle>Scope 1 (Direct Emissions) :</Subtitle>
          <Article>
            Scope 1 emissions are direct emissions from sources owned or
            controlled by us. We have included all scope 1 emissions in our
            footprint.
          </Article>
          <Subtitle>Scope 2 (Indirect Emissions) :</Subtitle>
          <Article>
            Scope 2 emissions are indirect emissions associated with importing
            (purchasing) electricity and other energy (heat and steam),
            including emissions from power plants that produce the energy we
            purchase and any fugitive emissions from processes we own or
            control. We have included all identified scope 2 emissions in our
            footprint.
          </Article>
        </Contents>
      </Page>
      <Page size={"A4"}>
        <Contents>
          <Subtitle>Scope 3 emissions (Indirect Emissions):</Subtitle>
          <Article>
            These are other indirect emissions that occur outside of our
            organisational boundaries but are attributable to our activities.
            This covers the recommended ISO 14064-1 categories of Indirect GHG
            emissions from transportation, products used, the use of sold
            products, and emissions from other sources, further divided into the
            15 scope 3 emission categories defined by the GHG Protocol.
          </Article>
          <Article>
            We have identified and quantified the relevant greenhouse gas (GHG)
            sources for each emissions category using appropriate quantification
            methodologies and emission factors, as outlined in the{" "}
            {brandConfig.brandName} methodology (see Annex). This approach
            ensures that our GHG inventory offers a comprehensive and
            transparent overview of our emissions across all our activities and
            operations.
          </Article>
        </Contents>
      </Page>
      <Page size={"A4"}>
        <Contents>
          <Subtitle>4.3 Results by Emissions Category</Subtitle>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.bold, styles.tableHeader]}>
              <Text style={emissionCategoriesTableStyles.colScope}>Name</Text>
              <Text style={emissionCategoriesTableStyles.colCategory}>
                Start Date
              </Text>
              <Text style={emissionCategoriesTableStyles.colNotes}>
                End Date
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
            </View>
            <View style={[styles.tableRow]} wrap={false}>
              <Text style={emissionCategoriesTableStyles.colScope}>Name</Text>
              <Text style={emissionCategoriesTableStyles.colCategory}>
                Start Date
              </Text>
              <Text style={emissionCategoriesTableStyles.colNotes}>
                End Date
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
            </View>
            <View style={[styles.tableRow]} wrap={false}>
              <Text style={emissionCategoriesTableStyles.colScope}>Name</Text>
              <Text style={emissionCategoriesTableStyles.colCategory}>
                Start Date
              </Text>
              <Text style={emissionCategoriesTableStyles.colNotes}>
                End Date
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
              <Text style={emissionCategoriesTableStyles.coltCO2}>Days</Text>
              <Text style={emissionCategoriesTableStyles.colPercentageOfTotal}>
                Info
              </Text>
            </View>
          </View>
          <Subtitle>4.4 Data and Emission factors</Subtitle>
          <Article>
            Our activity data was gathered from internal records and external
            suppliers. Emission factors were obtained from reliable secondary
            databases, including those published annually by DEFRA/BEIS/DESNZ.
            BOM Systems Ltd has independently verified custom emission factors.
            Any changes to published emission factors or updates to data sources
            have been documented and explained in the Annex.
          </Article>

          <Subtitle>4.5 Uncertainties and Accuracy Impacts</Subtitle>
          <Article>
            For each emission source, the activity data quality and the
            corresponding emission factor were rated on a scale ranging from
            Very Good Data Quality (4) to Basic Data Quality (1). By calculating
            the weighted average of these grades, we determined the overall
            quality of each emissions category for each reporting year. The
            details of the data quality categories are in the Annex.
          </Article>

          <Subtitle>4.6 Actions for Reducing Uncertainty</Subtitle>
          <Article>
            We are committed to continuously improving our reporting of GHG
            emissions to ensure comprehensive and transparent disclosure. To
            reduce uncertainty in our future greenhouse gas (GHG) inventories,
            we plan to enhance our data collection and monitoring systems to
            ensure that our reporting is more granular and consistent. We also
            want to focus on developing custom emission factors where feasible.
          </Article>
        </Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents></Contents>
      </Page>
      <Page size={"A4"}>
        <Contents>
          <Title>5. Annex - Methodology and Supporting Information</Title>

          <Subtitle>5.1 Quantification Model and Methodology</Subtitle>
          <Article>
            This report used the <Bold>{brandConfig?.brandName}</Bold>, an
            online tool developed and managed by{" "}
            <a
              href="https://www.imssystems.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              iMS Technologies
            </a>
            , as the model and tool for estimating GHG emissions. The
            methodology and approach comply with the GHG Protocol Corporate
            Accounting and Reporting Standard, The GHG Protocol Corporate Value
            Chain (Scope 3) Standard, and ISO 14064-1:2018.
          </Article>
          <Article>
            The calculator performed the GHG assessment based on activity data
            from our records, which was multiplied by externally published
            emission factors from reputable public sources, primarily the
            Department for Environment, Food & Rural Affairs (DEFRA) /BEIS.
          </Article>
          <Text>Activity Data x GHG Emission Factor = Total Emissions</Text>
          <Article>
            The Kyoto Protocol GHGs were calculated separately where appropriate
            and then converted and quantified as tonnes of carbon dioxide
            equivalent (tCO2e) to calculate their Global Warming Potential
            (GWP), expressed relative to the GWP of one unit of carbon dioxide.
            The GWPs used in our calculations are from the Intergovernmental
            Panel on Climate Change (IPCC) 'IPCC Global Warming Potential Values
            v 2.0 August 2024', using the AR6 values.
          </Article>

          <Article>
            Secondary emission factors related to volume, mass, and distances
            were obtained from the UK Government's Department for Environment,
            Food & Rural Affairs (DEFRA), the Department for Business and Energy
            Strategy (BEIS and the Department for Energy Security and Net Zero
            (DESNZ) Conversion Factors databases.
          </Article>
          <Article>
            Emission factors based on expenditure were sourced from DEFRA's UK
            and England's carbon footprint conversion factors, categorised by
            SIC code.
          </Article>
          <Article>
            Custom emission factors are typically based on an Environmental
            Product Declaration or other type of Life Cycle Assessment from
            supply chain partners based on ISO 14040-14044 standards. Quality
            standards such as boundary definitions, compliance, and completeness
            must be met. We require these to have been independently verified.
            They are otherwise based on published, verified information, such as
            publicly available energy tariffs. All custom factors we use meet
            the data quality requirements specified in the GHG Protocol
            Corporate Value Chain (Scope 3) Accounting and Reporting Standard.
          </Article>
          <Alert color="warning" className="border border-warning">
            The justification for this approach is that the{" "}
            <Bold> {brandConfig?.brandName}</Bold>
            has been verified against ISO 14064-1 by a UKAS-accredited
            Verification Body (Interface NRM Ltd) and, as such, complies with
            the Greenhouse Gas (GHG) Protocol and ensures emissions are
            accurately represented according to widely recognised standards and
            methodologies. The calculator is an online tool that ensures that it
            can be controlled and updated effectively and that all the emissions
            factors are locked to prevent calculation errors. This approach
            ensures that the results can be consistently reproduced.
          </Alert>

          <Subtitle>5.2 Base Year</Subtitle>
          <Article>
            Our base year shows changes in our emissions over time, allowing us
            to conduct comparative analyses in tracking and reporting GHG
            emissions and develop meaningful and consistent comparisons of
            emissions over time.
          </Article>
          <Article>
            Our base year was selected because it is representative and provides
            an accurate reference point for our operations, data availability,
            and organisational structure. It was the first year we recorded our
            emissions in sufficient detail to give a reliable emissions figure,
            and it avoids unusual trading years (such as those around COVID-19
            or unusual corporate activity).
          </Article>
          <Article>
            To consistently track our emissions over time, we shall recalculate
            base year emissions{" "}
            <Bold> according to the following recalculation policy</Bold>
            if significant changes in company structure or GHG inventory
            methodology occur. This will maintain consistency and enable
            meaningful comparisons of the inventory over time.
          </Article>
          <Article>
            We will recalculate the base year emissions under the following
            circumstances if they have a significant impact on the inventory:
          </Article>
          <Article>
            <Bold>Structural changes,</Bold> such as mergers, acquisitions,
            divestments, outsourcing, and insourcing;
          </Article>

          <Article>
            <Bold>Changes in calculation methodologies,</Bold> improvements in
            data accuracy, or discovery of significant errors;
          </Article>
          <Article>
            <Bold>Changes in the categories</Bold> or activities included in the
            Scope 3 inventory.
          </Article>
          <Article>
            Our policy defines a significant change as altering base year
            emissions by ten per cent or greater. As part of the base year
            emissions recalculation policy, we will establish and disclose this
            significance threshold that triggers base year emissions
            recalculations.
          </Article>

          <Article>
            If a recalculation is necessary, we will accurately recalculate the
            base year emissions using the same approach applied in the current
            reporting year, taking into account the identified changes or
            errors, and ensure consistency with the current reporting year. We
            will document the reasons for the recalculation, the steps taken,
            and the impact on the base year emissions data and update the GHG
            inventory.
          </Article>

          <Subtitle>5.3 Biogenic Emissions</Subtitle>
          <Article>
            Biogenic Emissions have been calculated and reported per Annex D of
            the ISO 14064-1 standard.
          </Article>
          <Subtitle>5.4 Treatment of Electricity</Subtitle>
          <Article>
            Electricity is treated according to Annex E of the ISO 14064-1
            standard. Our GHG assessment utilises both location-based and
            market-based reporting methods.{" "}
            <a href="#iso-14064-full-report-ref-1">[1]</a> This dual approach
            comprehensively explains our electricity-related GHG emissions. We
            ensure that the quality criteria of Annex E of ISO 14064-1 are met
            for our market-based reporting.
          </Article>

          <Title>6. Data Quality and Uncertainty</Title>
          <Article>
            The approach to data quality for this report is that the activity
            data were entered into the {brandConfig.brandName} using the
            qualification table shown below, with a qualitative data assessment,
            which uses the approach defined within the GHG Corporate Value Chain
            (Scope 3) Accounting and Reporting Standard (see Box 7.2).
          </Article>
        </Contents>
      </Page>
    </Document>
  );
  return (
    <PDFViewer height={1200} width={800}>
      {report}
    </PDFViewer>
  );
  return (
    <PDFDownloadLink document={report} fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Download now!"
      }
    </PDFDownloadLink>
  );
};

export default PDF;
