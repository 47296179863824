import React from "react";
import Categories from "../../sharedSections/reportingBoundaries/Index";
import NoParamsGuard from "../NoParamsGuard";

function Boundaries() {
  return (
    <NoParamsGuard>
      <Categories />
    </NoParamsGuard>
  );
}

export default Boundaries;
