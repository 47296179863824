import * as yup from "yup";
import CC_CONSTANTS from "../../../../constants";
import moment from "moment/moment";
export const locationFormValidaionSchema = yup.object({
  locationRef: yup.string().required().label("locationRef"),
  address: yup.object({
    label: yup.string().label("address"),
    value: yup.string().label("address"),
  }),
  descriptionOfActivities: yup.string().optional().label("locationRef"),
  ghgAssessmentInclusion: yup.object({
    label: yup
      .string()
      .oneOf(Object.values(CC_CONSTANTS.CC_GHG_INCLUSION_ASSESSMENT))
      .label("ghgAssessmentInclusion"),
    value: yup
      .string()
      .oneOf(Object.values(CC_CONSTANTS.CC_GHG_INCLUSION_ASSESSMENT))
      .label("ghgAssessmentInclusion"),
  }),
  comment: yup.string().optional().label("locationRef"),
});
export function getLocationFormState(apiResponse) {
  return {
    locationRef: apiResponse?.locationRef || "",
    address: {
      label: apiResponse?.address || null,
      value: apiResponse?.address || null,
    },
    descriptionOfActivities: apiResponse?.descriptionOfActivities || "",
    ghgAssessmentInclusion: {
      label: apiResponse?.ghgAssessmentInclusion || null,
      value: apiResponse?.ghgAssessmentInclusion || null,
    },
    comment: apiResponse?.comment || "",
  };
}
