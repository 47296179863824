import React from "react";
import { FormGroup, Select, Label } from "ims-ui-kit";
import InputError from "./InputError";
function SelectInput({
  label = "",
  error = "",
  mandatorySymbol = false,
  ...props
}) {
  return (
    <FormGroup>
      {label && (
        <Label>
          {label} {mandatorySymbol && <span className="text-danger">•</span>}
        </Label>
      )}
      <Select variant="filled" {...props} />
      {error && <InputError>{error}</InputError>}
    </FormGroup>
  );
}

export default SelectInput;
