import { Table } from "ims-ui-kit";
import React from "react";
import { useSingleYearReport } from "./store";
function DataTable() {
  const { report } = useSingleYearReport();
  return (
    <Table>
      <thead className="bg-secondary-extra-light">
        <tr>
          <th>Scope</th>
          <th>Category</th>
          <th>Notes</th>
          <th>tCO2e</th>
          <th>% of total</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(report?.scopeBasedEmissionResults).map((scope) => {
          return (
            <React.Fragment key={scope}>
              <tr>
                <td
                  rowSpan={
                    report?.scopeBasedEmissionResults[scope].categories.length +
                    1
                  }
                >
                  {scope}
                </td>
              </tr>
              {report?.scopeBasedEmissionResults[scope].categories.map(
                (cat) => {
                  return (
                    <React.Fragment key={cat.category}>
                      <tr>
                        <td>{cat.category}</td>
                        <td>-</td>
                        <td>{cat.totalCO2eEmissions}</td>
                        <td>{cat.percentageCO2eOfTotal}</td>
                      </tr>
                    </React.Fragment>
                  );
                }
              )}
              <tr className="text-dark bg-secondary-light">
                <td>Total</td>
                <td>{scope} </td>
                <td>-</td>
                <td>
                  {
                    report?.scopeBasedEmissionResults[scope]
                      .grandTotalCO2eEmissions
                  }
                </td>
                <td>
                  {
                    report?.scopeBasedEmissionResults[scope]
                      .grandPercentageCO2eOfTotal
                  }
                </td>
              </tr>
            </React.Fragment>
          );
        })}
        <tr className="text-dark bg-secondary-light">
          <td>Total results</td>
          <td>Scope 1, 2 & 3 </td>
          <td>-</td>
          <td>{report?.scopeBasedEmissionSummary.grandTotalCO2eEmissions}</td>
          <td>
            {report?.scopeBasedEmissionSummary.grandPercentageCO2eOfTotal}
          </td>
        </tr>
        <tr className="text-dark">
          <td>Out of scope</td>
          <td>{report?.outOfScopeEmissionResults?._id}</td>
          <td>-</td>
          <td>
            {report?.outOfScopeEmissionResults.totalGhgBiogenicCo2Emission}
          </td>
          <td>-</td>
        </tr>

        <tr className="text-dark ">
          <td rowSpan={3}>Intensity ratio</td>
        </tr>
        <tr className="text-dark ">
          <td>tCO2e/1£m of turnover </td>
          <td>-</td>
          <td>{report?.intensityRatio.totalCO2ePerMillionOfTurnOver}</td>
          <td>-</td>
        </tr>
        <tr className="text-dark">
          <td>tCO2e/employee </td>
          <td>-</td>
          <td>{report?.intensityRatio.totalCO2ePerEmployee}</td>
          <td>-</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default DataTable;
