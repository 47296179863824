import { Card, CardBody, Badge } from "ims-ui-kit";
import React from "react";
import { LuChevronRight } from "react-icons/lu";
import { Link } from "react-router-dom";

function CourseTile({
  index = 0,
  stepCounts = 0,
  name = "Not specified",
  description = "Not specified",
  onStart = () => {},
}) {
  return (
    <Card className={"rounded-3 border-0"}>
      <CardBody>
        <div className="">
          <h5 className="d-inline">{name}</h5>
          <span className="pull-right">
            <Badge fade="primary">{stepCounts} steps</Badge>
          </span>
        </div>
        <div className="mt-3 rounded-3 bg-secondary-extra-light p-3">
          <p className="mb-3">{description}</p>
          <Link
            to={"#"}
            onClick={() => {
              onStart();
            }}
          >
            <span className="text-dark font-bold">
              {" "}
              Learn <LuChevronRight />
            </span>
          </Link>
        </div>
      </CardBody>
    </Card>
  );
}

export default CourseTile;
