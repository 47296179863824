import { FormGroup, Input, Label } from "ims-ui-kit";
import React from "react";
import InputError from "./InputError";
function TextInput({
  label = "",
  error = "",
  mandatorySymbol = false,
  ...props
}) {
  return (
    <FormGroup>
      {label && (
        <Label>
          {label} {mandatorySymbol && <span className="text-danger">•</span>}
        </Label>
      )}
      <Input variant="filled" {...props} />
      {error && <InputError>{error}</InputError>}
    </FormGroup>
  );
}

export default TextInput;
