import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import Table from "../../../components/Table";
import NoParamsGuard from "../NoParamsGuard";
import { useParameters } from "../../../store/parametersStore";
import moment from "moment";
import CellEditableText from "../../../components/CellEditableText";
import * as ccParameterService from "../../../services/ccParameterService";
import { Card, CardBody } from "ims-ui-kit";
const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor("year", {
    header: "Reporting year",
  }),
  columnHelper.accessor("startDate", {
    header: "Start date",
  }),
  columnHelper.accessor("endDate", {
    header: "End date",
  }),
  columnHelper.accessor("emissionFactorDbYear", {
    header: "Emission factor database year",
  }),
  columnHelper.accessor("turnOver", {
    header: "Turn over",
    cell: ({ row, getValue }) => (
      <CellEditableText
        type="number"
        defaultValue={getValue()}
        onSave={(changes) =>
          ccParameterService.updateReportingYear(
            row.original.parameterId,
            row.original.yearId,
            {
              turnOver: Number(changes),
            }
          )
        }
      />
    ),
  }),
  columnHelper.accessor("employeeCount", {
    header: "Average employees",
    cell: ({ row, getValue }) => (
      <CellEditableText
        defaultValue={getValue()}
        onSave={(changes) =>
          ccParameterService.updateReportingYear(
            row.original.parameterId,
            row.original.yearId,
            {
              employeeCount: Number(changes),
            }
          )
        }
      />
    ),
  }),
  columnHelper.accessor("", {
    id: "actions",
    maxSize: 50, // has to be exactly 50
    header: ({ table }) => <div className="mt-row-actions"></div>,
    cell: ({ row }) => null,
  }),
];

function ReportingPeriods() {
  const { reportingPeriods } = useParameters();
  return (
    <NoParamsGuard>
      <Card className="rounded-3 border-0">
        <CardBody>
          <Table
            data={reportingPeriods}
            columns={columns}
            disableMultiSelection
            size={200}
          />
        </CardBody>
      </Card>
    </NoParamsGuard>
  );
}

export default ReportingPeriods;
