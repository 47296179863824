import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import "scss/app.scss";
import "ims-ui-kit/src/assets/css/nucleo-icons.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import MainLayout from "./layouts/main/Index";
import { mainRoutes, publicRoutes } from "./routes";
import { Toaster } from "react-hot-toast";
import { Tour } from "./components/Tour";

function App() {
  const element = useRoutes([
    {
      element: <MainLayout />,
      children: [
        ...mainRoutes,
        {
          path: "*",
          element: <Navigate to="/dashboard" />,
        },
      ],
    },
    ...publicRoutes,
  ]);
  return (
    <div>
      <Toaster />
      <Tour>{element}</Tour>
    </div>
  );
}

export default App;
