import React from "react";
import FullReport from "./FullReport";
import { ISO14064FullReportProvider } from "./store";
import { Container } from "ims-ui-kit";

function Iso14064FullReport() {
  return (
    <ISO14064FullReportProvider>
      <div className="bg-secondary-extra-light  py-5 ">
        <Container className="bg-white rounded-3 py-5">
          <FullReport />
        </Container>
      </div>
    </ISO14064FullReportProvider>
  );
}

export default Iso14064FullReport;
