import {
  Button,
  Navbar,
  Input,
  Form,
  InputGroup,
  InputGroupText,
} from "ims-ui-kit";
import React from "react";
import { HiMenuAlt2 } from "react-icons/hi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  Menu,
  MenuItem,
  Sidebar,
  menuClasses,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "images/logo-white.png";
import { sidebarData } from "./sidebarData";
import { IoMdLogOut } from "react-icons/io";
import { PiBellBold } from "react-icons/pi";
import { RiSearch2Line } from "react-icons/ri";
import LoginWithOrgProtected from "../LoginWithOrgProtected";
import { ParametersContextProvider } from "../../store/parametersStore";
import { OrganisationProvider } from "../../store/organisationStore";
import { useApplication } from "../../store/applicationStore";
import brandConfig from "config.js";
import TourStep from "../../components/TourStep";

const menuStyles = {
  padding: "7px",
  [`.${menuClasses.button}`]: {
    borderRadius: "8px",
    transition: ".3s ease-in-out",
    height: "auto",
    paddingRight: "15px",
    paddingLeft: "15px",
    paddingBottom: "10px",
    paddingTop: "10px",
    color: "white",
    "&:hover": {
      backgroundColor: brandConfig.primaryDarkColor + " !important",
      color: "white",
    },
    ["&." + menuClasses.active]: {
      backgroundColor: brandConfig.primaryDarkColor + " !important",
      color: "white",
    },
  },
};

function MainLayout() {
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(false);
  const [sidebarToggled, setSidebarToggled] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUserData } = useApplication();
  return (
    <LoginWithOrgProtected>
      <ParametersContextProvider>
        <OrganisationProvider>
          <div className="main-layout">
            <Sidebar
              collapsed={sidebarCollapsed}
              toggled={sidebarToggled}
              customBreakPoint="764px"
              onBackdropClick={() => setSidebarToggled(false)}
              rootStyles={{
                position: "sticky",
                top: 0,
                left: 0,
                height: "100vh",
                borderRight: "1px solid #CED4DA",
                [`.${sidebarClasses.container}`]: {
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: brandConfig.primaryColor,
                  position: "relative",
                  overflowY: "hidden",
                },
                [`&.${sidebarClasses.broken}`]: {
                  zIndex: 1051,
                },
              }}
            >
              <Menu rootStyles={menuStyles}>
                <MenuItem
                  rootStyles={{
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    // background: "white",
                  }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="w-50 my-2 ms-2"
                      alt="brand-name"
                      src={logo}
                    />
                  </div>
                </MenuItem>
                <TourStep stepId={"sidebar-navigations"}>
                  {sidebarData.map((menuitem) => (
                    <TourStep stepId={menuitem.id}>
                      <MenuItem
                        active={location.pathname === menuitem.path}
                        key={menuitem.path}
                        className="p-0"
                        icon={menuitem.icon}
                        onClick={() => navigate(menuitem.path)}
                      >
                        {menuitem.text}
                      </MenuItem>
                    </TourStep>
                  ))}
                </TourStep>
              </Menu>
              <Menu rootStyles={menuStyles}>
                <MenuItem
                  className="my-auto p-0 flex-end"
                  icon={<PiBellBold />}
                >
                  Notifications
                </MenuItem>
                <MenuItem
                  className="my-auto p-0 flex-end"
                  icon={<IoMdLogOut />}
                  onClick={() => navigate("/logout")}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Sidebar>
            <div className="main-container">
              <Navbar className="nav bg-white" expand="lg" container="fluid">
                <Button
                  size={"sm"}
                  id="sidebar-toggler"
                  outline
                  className="d-md-none border-0"
                  onClick={() => {
                    setSidebarToggled((t) => !t);
                  }}
                >
                  <HiMenuAlt2 />
                </Button>
                <Button
                  size={"sm"}
                  onClick={(e) => {
                    setSidebarCollapsed((c) => !c);
                  }}
                  outline
                  className="d-none d-md-inline border-0"
                >
                  {sidebarCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
                </Button>
                <Form className="w-50">
                  <InputGroup className={"m-0"}>
                    <InputGroupText>
                      <RiSearch2Line />
                    </InputGroupText>
                    <Input
                      variant={"filled"}
                      placeholder="Search parameters, activities or reports..."
                    />
                  </InputGroup>
                </Form>
                <div className="d-flex ms-auto py-1">
                  <Link to="/accounts/settings">
                    <div className="d-inline-flex">
                      <div id="profile-link" className="flex-shrink-0 mx-md-2">
                        <img
                          src={
                            currentUserData?.profileImageSrc ||
                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIQctMpfDaRxFb7YrryqRe1-2hK2r1getr-w&usqp=CAU"
                          }
                          alt="avatar"
                          className="avatar"
                        />
                      </div>
                      <p className="py-1 d-none d-md-block">
                        {currentUserData?.name}
                      </p>
                    </div>
                  </Link>
                </div>
              </Navbar>

              <Outlet />
            </div>
          </div>
        </OrganisationProvider>
      </ParametersContextProvider>
    </LoginWithOrgProtected>
  );
}

export default MainLayout;
