import React from "react";
import { useDashboard } from "./store";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Select,
  Table,
} from "ims-ui-kit";
import dashboardTopCardChartImageGreen from "../../assets/image/dashboard-top-card-chart-green.svg";
import dashboardTopCardChartImageRed from "../../assets/image/dashboard-top-card-chart-red.svg";
import CC_CONSTANTS from "../../constants";
import { FaLeaf } from "react-icons/fa6";
import { MdOutlineLightbulb } from "react-icons/md";
import { LuCloudCog } from "react-icons/lu";
import { TbTargetArrow } from "react-icons/tb";
import { FaHistory } from "react-icons/fa";
import ScopeEmissionChart from "./ScopeEmissionChart";
import IntensityRatioChart from "./IntensityRatioChart";
import { useApplication } from "../../store/applicationStore";
import { useParameters } from "../../store/parametersStore";
import EmissionsBreakDown from "./EmissionsBreakDown";
import { LuGoal } from "react-icons/lu";
import { MdOutlineCalendarToday } from "react-icons/md";
import CategoryIcon from "../../components/CategoryIcon";
import Loading from "../../components/Loading";
import TourStep from "../../components/TourStep";
function Dashboard() {
  const { parameter, reportingPeriods } = useParameters();
  const {
    report,
    isReportLoading,
    selectedReportingYear,
    selectReportingYear,
  } = useDashboard();
  const { currentUserData } = useApplication();
  if (isReportLoading) return <Loading />;
  if (!report)
    return <p className="text-center m-5">There is no data at the minute.</p>;
  const topCategories = Object.values(report?.scopeBasedEmissionResults)
    .reduce((finalList, currentScope) => {
      return [
        ...finalList,
        ...currentScope.categories?.map((cat) => ({
          name: cat.category,
          totalCO2eEmissions: cat.totalCO2eEmissions,
          percentageCO2eOfTotal: cat.percentageCO2eOfTotal,
        })),
      ];
    }, [])
    .sort((a, b) => (a.totalCO2eEmissions > b.totalCO2eEmissions ? -1 : 1))
    .slice(0, 3);
  return (
    <TourStep stepId="dashboard-section">
      <div className="main-dashboard border-top">
        <div className="content bg-secondary-extra-light">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span>
              <h4 className="d-inline">Carbon overview</h4>{" "}
              {/* <span>Carbon overview during reporting period</span> */}
            </span>
            <Select
              defaultValue={selectedReportingYear}
              options={reportingPeriods.map((rp) => ({
                value: rp.year,
                label: `${rp.startDate} - ${rp.endDate}`,
              }))}
              onChange={selectReportingYear}
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="top-bar">
                <Row className="py-4">
                  <Col md="3" className="border-end">
                    <div className="tile d-flex align-items-center justify-content-between py-2 px-4">
                      <div>
                        <p className="pb-2">Total Emissions</p>
                        <h4>
                          {parseInt(
                            report?.scopeBasedEmissionSummary
                              .grandTotalCO2eEmissions
                          )}
                        </h4>
                      </div>
                      <img src={dashboardTopCardChartImageGreen} />
                    </div>
                  </Col>
                  <Col md="3" className="border-end">
                    <div className="tile d-flex align-items-center justify-content-between py-2 px-4">
                      <div>
                        <p className="pb-2">Total Activities</p>
                        <h4>{report?.totalActivities || 0}</h4>
                      </div>
                      <img src={dashboardTopCardChartImageGreen} />
                    </div>
                  </Col>
                  <Col md="3" className="border-end">
                    <div className="tile d-flex align-items-center justify-content-between py-2 px-4">
                      <div>
                        <p className="pb-2">Custom Factors</p>
                        <h4>{report?.totalCustomFactors || 0}</h4>
                      </div>
                      <img src={dashboardTopCardChartImageRed} />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="tile d-flex align-items-center justify-content-between py-2 px-4">
                      <div>
                        <p className="pb-2">Biogenic Emissions</p>
                        <h4>
                          {" "}
                          {parseInt(
                            report?.outOfScopeEmissionResults
                              .totalGhgBiogenicCo2Emission
                          )}
                        </h4>
                      </div>
                      <img src={dashboardTopCardChartImageGreen} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  {" "}
                  <span className="icon-container-circle">
                    <FaLeaf />
                  </span>{" "}
                  Greenhouse Gas Emission Overview
                </CardHeader>
                <CardBody>
                  <EmissionsBreakDown />
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card>
                <CardHeader>
                  <span className="icon-container-circle">
                    <TbTargetArrow />
                  </span>{" "}
                  Target
                </CardHeader>
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td className="d-flex align-items-center border-0">
                          <span className="icon-container-square">
                            <MdOutlineCalendarToday />
                          </span>{" "}
                          Net zero target year
                        </td>
                        <td className="border-0">
                          {parameter?.netZeroTargtYear}
                        </td>
                      </tr>
                      <tr>
                        <td className="d-flex align-items-center border-0">
                          <span className="icon-container-square">
                            <LuGoal />
                          </span>{" "}
                          Reduction ambitions
                        </td>
                        <td className="border-0">
                          {parameter?.netZeroReductionPercentageAmbition}%
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <span className="icon-container-circle">
                    <MdOutlineLightbulb />
                  </span>{" "}
                  Emission insights
                </CardHeader>
                <CardBody>
                  <Table>
                    <tbody>
                      {Object.values(CC_CONSTANTS.CC_EMISSION_SCOPES).map(
                        (scope) => {
                          return (
                            <tr
                              key={
                                scope +
                                report?.scopeBasedEmissionResults[scope]
                                  .grandPercentageCO2eOfTotal
                              }
                            >
                              <td className="border-0">{scope}</td>
                              <td className="border-0">
                                {
                                  report?.scopeBasedEmissionResults[scope]
                                    .grandTotalCO2eEmissions
                                }
                                t -
                                {
                                  report?.scopeBasedEmissionResults[scope]
                                    .grandPercentageCO2eOfTotal
                                }
                                %
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              {/* <Card>
              <CardHeader>
                <span className="icon-container-circle">
                  <LuCloudCog />
                </span>{" "}
                Top Emission Category
              </CardHeader>
              <CardBody>
                <Table>
                  <tbody>
                    {topCategories.map((cat) => {
                      return (
                        <tr key={cat.name + cat.totalCO2eEmissions}>
                          <td className="d-flex align-items-center border-0">
                            <span className="icon-container-square">
                              <CategoryIcon category={cat.name} />
                            </span>{" "}
                            {cat.name}
                          </td>
                          <td className="border-0">
                            {cat.totalCO2eEmissions}t/
                            {cat.percentageCO2eOfTotal}%
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card> */}

              {/* <Card>
              <CardHeader>
                <span className="icon-container-circle">
                  <FaHistory />
                </span>{" "}
                Emissions History
              </CardHeader>
              <CardBody>
                <ScopeEmissionChart />
                <IntensityRatioChart />
              </CardBody>
            </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </TourStep>
  );
}

export default Dashboard;
