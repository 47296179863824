import React from "react";
import { useLocationParameter } from "../store";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiCurrentLocation } from "react-icons/bi";
import { Marker, GoogleMap } from "@react-google-maps/api";
import useGoogle from "../../../../store/applicationStore/hooks/useGoogle";
const mapHeight = 300;
export default function Drawer({}) {
  const { viewingLocation } = useLocationParameter();
  const { isGoogleJsAPILoaded } = useGoogle();
  if (!viewingLocation) return null;
  return (
    <div className="py-3">
      <h6>Geographical information</h6>
      <div className="bg-secondary-extra-light rounded-2 my-2 p-3">
        <small className="text-secondary">
          Reference: {viewingLocation.reference}
        </small>
        <p className="text-dark my-1">
          <HiOutlineLocationMarker /> {viewingLocation?.address}{" "}
        </p>
        <p>
          <BiCurrentLocation /> Longitude: {viewingLocation.lng} Latirude:{" "}
          {viewingLocation.lat}
        </p>
      </div>
      <h6>Map</h6>
      <div
        style={{ height: mapHeight }}
        className="my-2 rounded-3 overflow-hidden"
      >
        {isGoogleJsAPILoaded && (
          <GoogleMap
            center={{ lng: viewingLocation.lng, lat: viewingLocation.lat }}
            zoom={8}
            mapContainerClassName="h-100 w-100"
            options={{
              disableDefaultUI: true,
              zoomControl: false,
            }}
          >
            <Marker
              position={{ lng: viewingLocation.lng, lat: viewingLocation.lat }}
            />
          </GoogleMap>
        )}
      </div>
      <h6>Other details</h6>
      <div className="bg-secondary-extra-light rounded-2 my-2 p-3">
        <p className="text-dark">Activities</p>
        <p className="my-1">{viewingLocation.descriptionOfActivities}</p>
        <p className="text-dark">GHG assessment inclusion</p>
        <p className="my-1">{viewingLocation.ghgAssessmentInclusion}</p>
        <p className="text-dark">Comments</p>
        <p className="my-1">{viewingLocation.comment}</p>
      </div>
    </div>
  );
}
