import { Table } from "ims-ui-kit";
import React from "react";
import { useHistoricTrendReport } from "./store";
import CC_CONSTANTS from "../../../constants";
function DataTable() {
  const { report } = useHistoricTrendReport();
  return (
    <Table>
      <thead className="bg-secondary-extra-light">
        <tr>
          <th>Scope</th>
          <th>Category</th>
          <th>Notes</th>
          {report.scopeBasedEmissionResults[
            CC_CONSTANTS.CC_EMISSION_SCOPES.SCOPE_1
          ]?.map((data) => (
            <th key={data.year}>{data.year} - tCO2e</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(report?.scopeCategoryBasedEmissionResults).map((scope) => {
          return (
            <React.Fragment>
              <tr>
                <td
                  rowSpan={
                    report?.scopeCategoryBasedEmissionResults[scope].length + 1
                  }
                >
                  {scope}
                </td>
              </tr>
              {report?.scopeCategoryBasedEmissionResults[scope].map((cat) => {
                return (
                  <React.Fragment key={cat.category}>
                    <tr>
                      <td>{cat._id.category}</td>
                      <td>-</td>
                      {cat.calculations.map((calc) => (
                        <td key={calc.year}>{calc.totalCO2eEmissions}</td>
                      ))}
                    </tr>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </tbody>
    </Table>
  );
}

export default DataTable;
