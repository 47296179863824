import { Button, Card, CardBody, Col, Row,  } from "@ims-systems-00/ims-ui-kit";
import React, { useEffect } from "react";
import DateInput from "../../../components/DateInput";
import SelectInput from "../../../components/SelectInput";
import CC_CONSTANTS from "../../../constants";
import { useParameters } from "../../../store/parametersStore";
import {
  getParameterFormState,
  parameterFormValidaionSchema,
} from "./dto/parameter.dto";
import { useReportingOverview } from "./store";
import { MdArrowRightAlt } from "react-icons/md";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useForm } from "@ims-systems-00/ims-react-hooks";

function ReportingOverview() {
  const { parameter, isParameterLoading } = useParameters();
  const {
    dataModel,
    handleChange,
    validationErrors,
    initiateDataModel,
    handleSubmit,
    isBusy,
  } = useForm(getParameterFormState(), parameterFormValidaionSchema);
  useEffect(() => {
    initiateDataModel(getParameterFormState(parameter));
  }, [parameter]);
  const { updateParameter } = useReportingOverview();
  const [reportingoverviewRef] = useAutoAnimate();
  return (
    <div ref={reportingoverviewRef}>
      <Card className={"category-tile rounded-3 border-0"}>
        <CardBody>
          <Row>
            <Col md="6">
              <DateInput
                label="Reporting Start Date"
                timeFormat={false}
                value={dataModel.reportingStartDate}
                error={validationErrors.reportingStartDate}
                onChange={(changes) =>
                  handleChange({
                    field: "reportingStartDate",
                    value: changes,
                  })
                }
              />
            </Col>
            <Col md="6">
              <p className="text-danger fontt-weigh-bold">Warning</p>
              <p className="">
                Modifying the <span className="text-primary">Start date</span>{" "}
                resets your reporting period.
              </p>
            </Col>
            <Col md="6">
              <SelectInput
                label="Base Year"
                options={CC_CONSTANTS.CC_ALLOWED_NET_ZERO_REPORTING_YEARS.map(
                  (i) => ({ value: i, label: i })
                )}
                value={dataModel.baseReportingYear}
                error={validationErrors.baseReportingYear}
                onChange={(changes) =>
                  handleChange({
                    field: "baseReportingYear",
                    value: changes,
                  })
                }
              />
            </Col>
            <Col md="6">
              <SelectInput
                label="Reporting Year"
                options={CC_CONSTANTS.CC_ALLOWED_NET_ZERO_REPORTING_YEARS.map(
                  (i) => ({ value: i, label: i })
                )}
                value={dataModel.currentReportingYear}
                error={validationErrors.currentReportingYear}
                onChange={(changes) =>
                  handleChange({
                    field: "currentReportingYear",
                    value: changes,
                  })
                }
              />
            </Col>
            <Col md="6">
              <SelectInput
                label="Reporting Method"
                options={Object.values(CC_CONSTANTS.CC_REPORTING_METHODS).map(
                  (i) => ({
                    value: i,
                    label: i,
                  })
                )}
                value={dataModel.primaryReportingMethod}
                error={validationErrors.primaryReportingMethod}
                onChange={(changes) =>
                  handleChange({
                    field: "primaryReportingMethod",
                    value: changes,
                  })
                }
              />
            </Col>
            <Col md="6">
              <SelectInput
                label="Organisational Boundary"
                options={Object.values(
                  CC_CONSTANTS.CC_ORGANISATIONAL_BOUNDARIES
                ).map((i) => ({
                  value: i,
                  label: i,
                }))}
                value={dataModel.organisationalBoundary}
                error={validationErrors.organisationalBoundary}
                onChange={(changes) =>
                  handleChange({
                    field: "organisationalBoundary",
                    value: changes,
                  })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className={"category-tile rounded-3 border-0"}>
        <CardBody>
          <h4 className={"mb-4"}>Targets </h4>
          <Row>
            <Col md="6">
              <SelectInput
                label="Net Zero Target Year"
                options={CC_CONSTANTS.CC_ALLOWED_NET_ZERO_TARGET_YEARS.map(
                  (i) => ({
                    value: i,
                    label: i,
                  })
                )}
                value={dataModel.netZeroTargtYear}
                error={validationErrors.netZeroTargtYear}
                onChange={(changes) =>
                  handleChange({
                    field: "netZeroTargtYear",
                    value: changes,
                  })
                }
              />
            </Col>
            <Col md="6">
              <SelectInput
                label="Carbon Reduction Target (%)"
                options={Array.from({ length: 21 }, (v, i) => i + 80).map(
                  (i) => ({
                    value: i,
                    label: i,
                  })
                )}
                value={dataModel.netZeroReductionPercentageAmbition}
                error={validationErrors.netZeroReductionPercentageAmbition}
                onChange={(changes) =>
                  handleChange({
                    field: "netZeroReductionPercentageAmbition",
                    value: changes,
                  })
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-2 px-3">
        <Button
          color={"primary"}
          className={"rounded-pill border-0"}
          disabled={isBusy}
          onClick={(e) => {
            handleSubmit(e, async (d) => {
              await updateParameter(parameter?._id, {
                reportingStartDate: d.reportingStartDate.date,
                baseReportingYear: d.baseReportingYear.value,
                currentReportingYear: d.currentReportingYear.value,
                primaryReportingMethod: d.primaryReportingMethod.value,
                organisationalBoundary: d.organisationalBoundary.value,
                netZeroTargtYear: d.netZeroTargtYear.value,
                netZeroReductionPercentageAmbition:
                  d.netZeroReductionPercentageAmbition.value,
              });
            });
          }}
        >
          {isBusy ? "Updating configuration..." : "Update configuration"}
          <MdArrowRightAlt />
        </Button>
      </div>
    </div>
  );
}

export default ReportingOverview;
