import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import FullReport from "./FullReport";
import { ISO14064FullReportProvider } from "./store";
import { Col, Container, Row } from "@ims-systems-00/ims-ui-kit";
import { Box } from "../../../components/Box";
import PDF from "./PDF";
import DemoChart from "./Chart";
import { useScreenshot } from "use-react-screenshot";
function Iso14064FullReport() {
  const chart = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  useEffect(() => {
    setTimeout(() => {
      if (chart.current) {
        let c = chart.current.childNodes[0]?.childNodes;
        takeScreenshot(chart.current);
        console.log(chart.current);
      }
    }, 500);
  }, [chart]);
  return (
    <ISO14064FullReportProvider>
      <Container className="py-5">
        <div className="py-5 ">
          <Row>
            <Col md="9" className="mx-auto">
              {/* <img width={900} src={image} />
              <div ref={chart}>
                <DemoChart />
              </div>
              <PDF /> */}
              <FullReport />
            </Col>
          </Row>
        </div>
      </Container>
    </ISO14064FullReportProvider>
  );
}

export default Iso14064FullReport;
