import { Col, Row } from "ims-ui-kit";
import CourseTile from "../../../components/CourseTile";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useTour } from "../../../components/Tour";
import { useNavigate } from "react-router-dom";
import CC_CONSTANTS from "../../../constants";
import useCourses from "../../../hooks/courses";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

function Guidelines() {
  const { startTour } = useTour();
  const courses = useCourses();
  return (
    <div className="guidelines-container">
      <Row>
        <Col md="4">
          {Object.keys(courses).map((course) => {
            return (
              <CourseTile
                stepCounts={courses[course].steps.length}
                name="Navigation system"
                description="Learn how to naviagate through the system with simple an interactive steps."
                onStart={() => {
                  startTour(courses[course]);
                }}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
}

export default Guidelines;
