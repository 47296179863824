import React, { useContext, useState } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
export const Context = React.createContext({
  setSteps: function () {},
  startTour: function () {},
  setRun: function () {},
  setCallback: function () {},
  markTourAsEnded: function () {},
});
import brandConfig from "config.js";
export function useTour() {
  return useContext(Context);
}
function emptyCallback() {}
export function Tour({ children }) {
  const [run, setRun] = useState(false);
  const [callback, setCallback] = useState(emptyCallback);
  const [steps, setSteps] = useState([]);
  function startTour(course) {
    setRun(true);
    setSteps(course.steps || []);
    setCallback(() => course.callback || emptyCallback);
  }
  function markTourAsEnded() {
    setRun(false);
    setCallback(() => emptyCallback);
    setSteps([]);
  }

  return (
    <React.Fragment>
      <Joyride
        run={run}
        steps={steps}
        callback={function (data) {
          let { status } = data;
          callback(data);
          // doing generic staff here...
          if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            markTourAsEnded(); // Stop the tour if finished or skipped
          }
        }}
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            backgroundColor: "#e3ffeb",
            overlayColor: "rgba(79, 26, 0, 0.4)",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 900,
            zIndex: 1000,
          },
        }}
      />
      <Context.Provider
        value={{
          setSteps,
          setCallback,
          setRun,
          startTour,
          markTourAsEnded,
        }}
      >
        {children}
      </Context.Provider>
    </React.Fragment>
  );
}
