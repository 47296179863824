import { useCallback, useEffect, useState } from "react";
import * as ccParameterService from "../../services/ccParameterService";
import useAPIResponse from "../../hooks/apiResponse";
import moment from "moment";

export default function useStore() {
  const [parameter, setParameter] = useState(null);
  const [isParameterLoading, setIsParameterLoading] = useState(true); // defaults to true
  const { handleError } = useAPIResponse();
  const loadParameter = useCallback(async function () {
    try {
      setIsParameterLoading(true);
      let response = await ccParameterService.listParameters();
      setParameter(response?.data?.ccParameters[0] || null);
    } catch (err) {
      handleError(err);
    } finally {
      setIsParameterLoading(false);
    }
  }, []);
  useEffect(() => {
    loadParameter();
  }, []);
  let getReportingPeriods = useCallback(function (parameter) {
    let baseStartDateConfig = new Date(parameter?.reportingStartDate);
    let baseEndDateConfig = new Date(parameter?.reportingStartDate);
    baseEndDateConfig.setDate(baseStartDateConfig.getDate() - 1);
    let today = new Date();
    if (parameter) {
      return parameter?.reportingYears
        ?.filter((yearData) => {
          // we are keeping the date table within the range of starting year and current year for good UX.
          return (
            yearData.year >= baseStartDateConfig.getFullYear() &&
            yearData.year < today.getFullYear()
          );
        })
        .map((yearData) => {
          let startDate = new Date(baseStartDateConfig);
          startDate.setFullYear(yearData.year);
          let endDate = new Date(baseEndDateConfig);
          endDate.setFullYear(yearData.year + 1);
          return {
            yearId: yearData._id,
            parameterId: parameter?._id,
            year: yearData.year + 1,
            startDate: moment(startDate).format("DD/MM/YYYY"),
            endDate: moment(endDate).format("DD/MM/YYYY"),
            emissionFactorDbYear:
              yearData.year + parameter?.emissionFactorDBYearCountFactor,
            turnOver: yearData.turnOver,
            employeeCount: yearData.employeeCount,
          };
        });
    } else return [];
  }, []);
  return {
    parameter,
    reportingPeriods: getReportingPeriods(parameter),
    isParameterLoading,
    loadParameter,
  };
}
