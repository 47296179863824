import React from "react";
import CommingSoon from "../../../components/CommingSoon";
import NoParamsGuard from "../NoParamsGuard";

function AdvancedTracking() {
  return (
    <NoParamsGuard>
      <CommingSoon align="center" />
    </NoParamsGuard>
  );
}

export default AdvancedTracking;
